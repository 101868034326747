.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure footer is at the bottom */
    height: calc(100vh - 80px);  /* Set the height to the entire height of the screen */
    width: 30%;
    background: linear-gradient(to bottom, #212121b4, #5a5959);
    padding: 20px;
    padding-bottom: 0px !important;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.15);
    border-radius: 1px;
    box-sizing: border-box;
    color: white;
}

.input-field, .select-field {
    width: calc(100%);
    outline: none;
    padding: 10px;
    margin-bottom: 10px;
    border: 3px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s; /* Smooth transition for border color */
}

.input-field:hover, .select-field:hover {
    border-color: #e99c12; /* Change border color on hover */
}

.search-button {
    width: calc(100%);
    padding: 10px;
    background-color: #e99c12;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Subtle shadow */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transitions */
}

.search-button:hover {
    background-color: #004494;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Enhanced shadow on hover */
}

.select-field {
    
    width: calc(100%);
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s, background-color 0.3s; /* Smooth transition for border and background */
    background-color: white; /* Light background for the dropdown */
    color: #333; /* Darker text for better readability */
    font-size: 16px; /* Larger font size */
    appearance: none; /* Removes default system appearance */
    background-repeat: no-repeat;
    background-position: right 10px center; /* Positioning the arrow to the right */
    background-size: 12px; /* Size of the arrow */
}

.select-field:hover, .select-field:focus {
    border-color: #e99c12; /* Enhanced border color on hover and focus */
    background-color: #f8f8f8; /* Slightly darker background on hover/focus */
}

.store-list {
    flex-grow: 1; /* Takes up all available space */
    overflow-y: auto; /* Allows scrolling within the element */
    overflow-x: hidden; /* Prevents horizontal scroll */
    margin-top: 20px;
    scrollbar-width:thin; /* For Firefox */
    scrollbar-color: #adadad #b8b7b700;
}
.store-list::-webkit-scrollbar {
    width: 18px; /* Adjust the width of the scrollbar */
}

.store-list::-webkit-scrollbar-track {
    background: #fcf1f1; /* Track color */
}

.store-list::-webkit-scrollbar-thumb {
    background-color: #e99c12; /* Thumb color */
    border-radius: 4px;
    border: 2px solid #1f1717;
}
.store-item {
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 3px 6px rgb(0, 0, 0);
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.store-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 6px rgb(0, 0, 0);
}

.store-header {
    display: flex;
    align-items: center;
}

.store-header img {
    width: 60px;
    height: 60px;
    margin-right: 15px;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.226));
    object-fit: contain;
}

.store-header h3 {
    margin: 0;
    color: #353535;
    flex: 1;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.226));
    font-size: 23px;
}

.store-item h3 {
    margin: 0;
    color:#353535;
    margin:0px 5px;
}

.store-status {
    font-size: 14px;
    font-weight: bold;
    margin:5px 5px;
}
.input-field{
    font-size: 16px !important;
}
.preorder-button {
    background-color: #5a5a5a;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 15px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
    font-size: 14px;
}

.preorder-button:hover {
    background-color: #d80f0f;
    color: #ffffff;
}
.preorder-button:disabled {
    background-color: #5a5a5a;
    cursor: not-allowed;
}
.no-store {
    color: #ffffff;
    font-size: 25px;
    text-shadow: 4px 4px 7px #000000;
}
@media (max-width: 768px) {
    .sidebar {
        width: 100%; 
        padding: 10px; /* Reduced padding on smaller screens */
        height: auto; /* Reduced margin top for more space */
    }
    .input-field, .input-field:focus {
        font-size: 16px !important; /* Ensures font size is at least 16px to prevent iOS zoom */
    }

    .input-field, .select-field, .search-button {
        padding: 8px; /* Reduced padding for input and button */
        margin-bottom: 8px; /* Reduced margin bottom */
    }

    .store-list {
        margin-top: 10px;
        height: 200%; /* Reduced margin top for more space */
    }

    .store-item {
        padding: 8px 8px; /* Reduced padding for each store item */
        margin-bottom: 18px; /* Reduced margin bottom for each store item */
    }
}

.pac-item {
    font-size: 16px; /* Set your desired font size */
}

.pac-item-query {
    font-size: 16px; /* Ensure query part matches the item size if needed */
}

.footer {
    background-color: #dadada; /* Dark background color */
    color: rgb(0, 0, 0); /* White text color */
    text-align: center; /* Center the text */
    padding: 5px 0; /* Padding for top and bottom */
    box-shadow: 0 -6px 15px rgba(0, 0, 0, 0.6); /* Even more shadow on top */
    width: 100%; /* Ensure it takes full width of the sidebar */
    margin: 0!important; /* Push footer to the bottom */
}
.footer p {
    margin: 0; /* Remove default margin */
    font-size: 15px; /* Font size */
}
.footer p:last-child {
    margin: 0; /* Remove default margin */
    font-size: 12px; /* Font size */
}
@media (max-width: 768px) {
    .footer {
        display: none;
    }
}


/* ... existing styles ... */

@keyframes border-transform {
    0%,100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; }
    14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; }
    28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; }
    42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; }
    56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; }
    70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; }
    84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; }
  }
  
  .progress-wrap {
    position: fixed;
    right: 20px;
    bottom: 20px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.767);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    transition: all 200ms linear;
  }
  
  .progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    background-color: #ce1f1fd3;
  }
  
  .progress-wrap::after {
    position: absolute;
    content: '\2191'; /* Up arrow Unicode */
    text-align: center;
    line-height: 50px;
    font-size: 26px;
    color: #ecedf3;
    left: 0;
    top: 0;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    z-index: 1;
    transition: all 200ms linear;
  }
  
  .progress-wrap:hover::after {
    color: #000000;
    
  }
  
  .progress-wrap svg path {
    fill: none;
  }
  
  .progress-wrap svg.progress-circle path {
    stroke: rgb(50, 207, 50);
    stroke-width: 6;
    box-sizing: border-box;
    transition: all 200ms linear;
  }
  .show-all-stores-button {
    width: 100%;
    padding: 12px 20px;
    background-color: #e9e9e9;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    box-shadow: 0 5px 5px rgba(119, 119, 119, 0.993);
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .show-all-stores-button:hover {
    background-color: #ce1f1f;
    box-shadow: 0 5px 5px rgba(119, 119, 119, 0.993);
    transform: translateY(-2px);
    color: #ffffff;
  }
  
  .show-all-stores-button:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }